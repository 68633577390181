// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-faq-js": () => import("./../../../src/pages/Home/FAQ.js" /* webpackChunkName: "component---src-pages-home-faq-js" */),
  "component---src-pages-home-reg-form-js": () => import("./../../../src/pages/Home/RegForm.js" /* webpackChunkName: "component---src-pages-home-reg-form-js" */),
  "component---src-pages-home-start-js": () => import("./../../../src/pages/Home/Start.js" /* webpackChunkName: "component---src-pages-home-start-js" */),
  "component---src-pages-home-terms-js": () => import("./../../../src/pages/Home/Terms.js" /* webpackChunkName: "component---src-pages-home-terms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-report-js": () => import("./../../../src/pages/report.js" /* webpackChunkName: "component---src-pages-report-js" */)
}

